import React from "react";
import ReactApexChart from "react-apexcharts";
export function Newsletter() {
  let NewsLetterData = {
    series: [
      {
        name: "subcribed",
        data: [65, 75, 85, 75, 65, 85, 75, 80, 75],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: "#BCB8D0",
        points: "red"
        
      },
      title: {
        align: "left",
      },
      grid: {
        row: {
          colors: ["#FFFFFF", "transparent"],
          opacity: 0.25,
        },
      },
      xaxis: {
        categories: [
          "Apr1",
          "Apr2",
          "Apr3",
          "Apr4",
          "Apr5",
          "Apr6",
          "Apr7",
          "Apr8",
          "Apr9",
        ],
      },
    },
  };

  return (
    <>
      <div className={`card card-custom `}>
        <div className="card-body pb-3">
          <h1>Total subscribed users to newsletter</h1>
        </div>
        <div id="chart">
          <ReactApexChart
            type="line"
            height={350}
            options={NewsLetterData.options}
            series={NewsLetterData.series}
          />
        </div>
      </div>
    </>
  );
}

import React, { useMemo } from "react";
import { CampaignsGrouping } from "./campaigns-grouping/CampaignsGrouping";
import { CampaignsTable } from "./campaigns-table/CampaignsTable";
import { useCampaignsUIContext } from "./CampaignsUIContext";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { CampaignsFilter } from "./campaigns-filter/CampaignsFilter";
import { Link } from "react-router-dom";

export function CampaignsCard() {
  const campaignsUIContext = useCampaignsUIContext();
  const campaignsUIProps = useMemo(() => {
    return {
      ids: campaignsUIContext.ids,
      newCampaignButtonClick: campaignsUIContext.newCampaignButtonClick,
    };
  }, [campaignsUIContext]);

  return (
    <div>
      <div className="mt-4">
        <Link className="header-btn d-flex" to={"/dashboard"}>
          <p>{" < "} </p>
          <span>Back</span>
        </Link>
        <div>
          <p className="header-text">Existing Campaigns</p>
        </div>
      </div>
      <Card>
        <CardBody>
          <CampaignsFilter />
          {campaignsUIProps.ids.length > 0 && <CampaignsGrouping />}
          <CampaignsTable />
        </CardBody>
      </Card>
    </div>
  );
}

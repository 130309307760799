import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BUDGET_RANGE, INDUSTRY_DATA, METRICS } from "../../../../../_metronic/_helpers/constant";
import * as campaignActions from "../../../../../_redux/campaigns/campaignsActions";
import { EXPERIENCE_DATA } from "../../../../../_metronic/_helpers/constant";
import { OPTIONS } from "../../../../../_metronic/_helpers/constant";
// import Slider from "../../Dialogs/Slider";
import { InputSplit } from "../InputSplit";
import { Overview } from "./OverviewCampaign";
import { Link } from "react-router-dom";
import CountrySelector from "./countries";
import { useParams } from "react-router-dom";
import { AddIndustry } from "./IndustryAdd";
import Select from "react-select";
// import { Metrics } from "./metrics";
import { SuccessModal } from "../Modals/successModal";
import { ErrorModal } from "../Modals/errorModal";
import CampaignMetrics from "./Metrics/CampaignMetrics";

export function CreateCampaign({ step, setStep, campaignName, setCampaignName, campaignDataObject }) {
  const [modalShow, setModalShow] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [campaginKeysArray, setCampaginKeysArray] = useState();
  const [campaginChildKeysArray, setCampaginChildKeysArray] = useState();
  const [addIndustry, setAddIndustry] = useState();
  const [show, setShow] = useState(false);
  const [campaignMatrixData, setCampaignMatrixData] = useState(METRICS);
  const [secondPageData, setSecondPageData] = useState(campaignDataObject);
  const [firstPageData, setFirstPageData] = useState({
    campaignName: "",
    country: "",
    budgetBracket: { min: 0, max: 0, id: "" },
    industry: { name: "", label: "" },
    objective: { name: "", label: "" },
    industryAge: "0",
    userExperience: "0",
  });

  const dispatch = useDispatch();

  const { id } = useParams();

  const showHandler = () => {
    setShow(true);
  };

  const hideHandler = () => {
    setShow(false);
  };

  let industry = {
    title: addIndustry,
  };
  const { campaignForEdit, industryData, campaignError } = useSelector(
    (state) => ({
      campaignError: state.campaigns.campaignError,
      industryData: state.campaigns.industries,
      campaignForEdit: state.campaigns.campaignForEdit,
    }),
    shallowEqual
  );

  const onhandleChange = (e) => {
    e.persist();
    setAddIndustry(e.target.value);
  };

  const handleCreateCampaign = () => {
    if (id !== undefined && campaignForEdit !== undefined) {
      dispatch(
        campaignActions.updateCampaign(
          id,
          {
            ...firstPageData,
            ...secondPageData,
            ...campaignMatrixData,
            industry: firstPageData.industry.value,
            objective: firstPageData.objective.label,
          },
          setModalShow
        )
      );
    } else {
      dispatch(
        campaignActions.createCampaign(
          {
            ...firstPageData,
            ...secondPageData,
            ...campaignMatrixData,
            industry: firstPageData.industry.value,
            objective: firstPageData.objective.label,
          },
          setModalShow
        )
      );
    }
  };

  useEffect(() => {
    if (METRICS) {
      setCampaignMatrixData({ ...METRICS });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(campaignActions.getIndustry());
    if(window.location.pathname === '/campaign-create'){
      dispatch(campaignActions.getCustomCampaign());
    }
    if (id) {
      dispatch(campaignActions.fetchCampaign(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (campaignError && campaignError.length) {
      setErrorModalShow(true);
    }
    // eslint-disable-next-line
  }, [campaignError]);

  useEffect(() => {
    if (campaignDataObject && id === undefined && campaignForEdit === undefined) {
      setSecondPageData({ ...campaignDataObject.existingObjectives });
    } else if (id && campaignForEdit !== undefined) {
      let firstPage = {
        campaignName: campaignForEdit.campaignName,
        country: campaignForEdit.country,
        industryAge: campaignForEdit.industryAge,
        userExperience: campaignForEdit.userExperience,
        industry: {
          ...campaignForEdit.industry,
          label: campaignForEdit.industry?.title,
        },
        objective: {
          label: campaignForEdit.objective,
        },
        budgetBracket: { ...campaignForEdit.budgetBracket },
      };

      let campaignMatrix = {
        googleSearchEngine: { ...campaignForEdit.googleSearchEngine },
        bingSearchEngine: { ...campaignForEdit.bingSearchEngine },
        yandexSearchEngine: { ...campaignForEdit.yandexSearchEngine },
        facebookSocialMedia: { ...campaignForEdit.facebookSocialMedia },
        twitterSocialMedia: { ...campaignForEdit.twitterSocialMedia },
        linkedinSocialMedia: { ...campaignForEdit.linkedinSocialMedia },
        snapchatSocialMedia: { ...campaignForEdit.snapchatSocialMedia },
        googleDisplayEngine: { ...campaignForEdit.googleDisplayEngine },
        crietoDisplayEngine: { ...campaignForEdit.crietoDisplayEngine },
      };

      let secondPage = {
        marketingIndustryBreakdown: {
          ...campaignForEdit.marketingIndustryBreakdown,
        },
        semSearchBudget: { ...campaignForEdit.semSearchBudget },
        googleSemBudget: { ...campaignForEdit.googleSemBudget },
        bingSemBudget: { ...campaignForEdit.bingSemBudget },
        yandexSemBudget: { ...campaignForEdit.yandexSemBudget },
        socialMediaBudget: { ...campaignForEdit.socialMediaBudget },
        facebookSmBudget: { ...campaignForEdit.facebookSmBudget },
        twitterSmBudget: { ...campaignForEdit.twitterSmBudget },
        linkdinSmBudget: { ...campaignForEdit.linkdinSmBudget },
        snapchatSmBudget: { ...campaignForEdit.snapchatSmBudget },
        displayBudget: { ...campaignForEdit.displayBudget },
        googleDisplay: { ...campaignForEdit.googleDisplay },
        crietoDisplayBudget: { ...campaignForEdit.crietoDisplayBudget },
      };
      setCampaignMatrixData({ ...campaignMatrix });
      setCampaignName(campaignForEdit.campaignName);
      setFirstPageData({ ...firstPage });
      setSecondPageData({ ...secondPage });
    }
    // eslint-disable-next-line
  }, [campaignDataObject, id, campaignForEdit]);

  useEffect(() => {
    if (campaignName) {
      setFirstPageData({ ...firstPageData, campaignName: campaignName });
    }
    // eslint-disable-next-line
  }, [campaignName]);

  useEffect(() => {
    let campaginKeys = [];
    let campaginChildKeys = [];
    if (secondPageData) {
      campaginKeys = Object.keys(secondPageData);
      if (campaginKeys?.length) {
        setCampaginKeysArray([...campaginKeys]);
        campaginKeys.map((item) => {
          return campaginChildKeys.push(Object.keys(secondPageData[item]));
        });
        if (campaginKeys?.length) {
          setCampaginChildKeysArray([...campaginChildKeys]);
        }
      }
    }
  }, [secondPageData]);

  return (
    <>
      <div className="createCampaign">
        <div className="container">
          {step === 0 && (
            <div className="">
              <div className="row">
                <div className="col-md-6">
                  <div className="addIndustry">
                    <div className="pageText mb-3">Select Industry</div>

                    <div className="select">
                      <Select
                        value={{
                          name: firstPageData?.industry?.label ? firstPageData?.industry?.label : "Select Industry",
                          label: firstPageData?.industry?.label ? firstPageData?.industry?.label : "Select Industry",
                        }}
                        options={
                          industryData && industryData.length
                            ? industryData.map((item) => {
                                return { label: item.title, value: item.id };
                              })
                            : []
                        }
                        onChange={(data) => {
                          setFirstPageData({
                            ...firstPageData,
                            industry: { ...data },
                          });
                        }}
                      />
                    </div>

                    <div className="industry">
                      <Link className="industry mb-3" to="#" onClick={showHandler}>
                        Add New Industry
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="Countries">
                    <div className="pageText mb-3">Select Country</div>
                    <div className="select">
                      <CountrySelector
                        countryName={firstPageData.country}
                        setCountryName={(name) =>
                          setFirstPageData({
                            ...firstPageData,
                            country: name,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="ml-4">
                <div className="pageText mb-5 mt-10 fs-5 fw-bold">Define Budget Bracket</div>
                {BUDGET_RANGE.map((item, index) => {
                  return (
                    <button
                      key={index}
                      id={item.label}
                      className={`btn-default mt-2 ${firstPageData.budgetBracket.id === item.id ? "btn-active" : ""}`}
                      onClick={(e) =>
                        setFirstPageData({
                          ...firstPageData,
                          budgetBracket: {
                            min: item.min,
                            max: item.max,
                            id: item.id,
                          },
                        })
                      }>
                      {item.label}
                    </button>
                  );
                })}
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="Objectives">
                    <div className="pageText mb-3">Define Objectives</div>

                    <Select
                      value={{
                        name: firstPageData?.objective?.label ? firstPageData?.objective?.label : "Select objectives",
                        label: firstPageData?.objective?.label ? firstPageData?.objective?.label : "Select objectives",
                      }}
                      options={
                        OPTIONS && OPTIONS.length
                          ? OPTIONS.map((item) => {
                              return { label: item.title, value: item.id };
                            })
                          : []
                      }
                      onChange={(data) => {
                        setFirstPageData({
                          ...firstPageData,
                          objective: { ...data },
                        });
                        // let existingObjective = {
                        //   ...campaignDataObject.existingObjectives,
                        // };
                        // setSecondPageData({ ...existingObjective });
                        // if (data.label === "Lead") {
                        //   let leadObjective = {
                        //     ...campaignDataObject.leadObjectives,
                        //   };
                        //   setSecondPageData({ ...leadObjective });
                        // } else {
                        //   let existingObjective = {
                        //     ...campaignDataObject.existingObjectives,
                        //   };
                        //   setSecondPageData({ ...existingObjective });
                        // }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="Industry ml-4">
                <div className="pageText mb-3">Industry Age</div>

                {INDUSTRY_DATA.map((item, index) => {
                  return (
                    <button
                      key={index}
                      id={item.label}
                      className={`btn-default mt-2 ${firstPageData.industryAge === item.label ? "btn-active" : ""}`}
                      onClick={(e) =>
                        setFirstPageData({
                          ...firstPageData,
                          industryAge: e.target.id,
                        })
                      }>
                      {item.label}
                    </button>
                  );
                })}
              </div>

              <div className="Experience ml-4">
                <div className="pageText mb-3">Experience In the Industry</div>

                {EXPERIENCE_DATA.map((item, index) => {
                  return (
                    <button
                      key={index}
                      id={item.label}
                      className={`btn-default mt-2 ${firstPageData.userExperience === item.label ? "btn-active" : ""}`}
                      onClick={(e) =>
                        setFirstPageData({
                          ...firstPageData,
                          userExperience: e.target.id,
                        })
                      }>
                      {item.label}
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          {step === 1 && (
            <div>
              <h5 className="mb-5">Enter budget split breakdown for the following</h5>
              <div>
                {campaginKeysArray?.map((item, index) => {
                  return (
                    <InputSplit
                      heading={item}
                      key={index}
                      childArray={campaginChildKeysArray[index]}
                      setCamapignData={setSecondPageData}
                      camapignData={secondPageData}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="mt-5">
              <CampaignMetrics
                campaignMatrix={campaignMatrixData}
                setCampaignMatrix={setCampaignMatrixData}
                metricsData={campaignMatrixData}
              />
            </div>
          )}

          {step === 3 && (
            <div className="mt-5">
              <Overview
                campaignMatrix={campaignMatrixData}
                setCampaignMatrix={setCampaignMatrixData}
                firstPageData={firstPageData}
                camapignData={secondPageData}
                childArray={campaginChildKeysArray}
                setCamapignData={setSecondPageData}
                campaginKeysArray={campaginKeysArray}
              />
            </div>
          )}
          <div className="mt-7 mb-8">
            <hr />
            <div className="d-flex">
              <button
                disabled={step === 0}
                onClick={() => {
                  setStep(step === 0 ? step : step - 1);
                }}
                className="btn">
                <b>{"<"} Previous</b>
              </button>

              <div className="ml-auto">
                {step < 3 ? (
                  <button
                    onClick={() => {
                      setStep(step === 3 ? step : step + 1);
                    }}
                    className="btn">
                    <b>Next {">"}</b>
                  </button>
                ) : (
                  <button onClick={handleCreateCampaign} className="btn">
                    <b>Launch</b>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddIndustry
        show={show}
        onHide={hideHandler}
        onChange={onhandleChange}
        addIndustry={addIndustry}
        setAddIndustry={setAddIndustry}
        industry={industry}
      />
      <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />

      <ErrorModal show={errorModalShow} campaignError={campaignError} onHide={() => setErrorModalShow(false)} />
    </>
  );
}

import React from "react";
import { ProgressBar } from "react-bootstrap";

export function BreakdownByIndustry({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-body pb-1">
          <div className="d-flex mb-6 mt-8">
            <h4 className="rate-desc">Breakdown by Industry</h4>
            <h4 className="ml-auto">See complete breakdown</h4>
          </div>

          <div className="mb-3">
            <ProgressBar variant="info" now={95} />
          </div>
          <div className="d-flex">
            <span className="mb-4">Arts, Entertainment, and Recreation</span>
            <p className="bar-percent ml-auto">95%</p>
          </div>

          <div className="mt-5 mb-3">
            <ProgressBar variant="info" now={65} />
          </div>
          <div className="d-flex">
            <span className="mb-4">Wholesale Trade</span>
            <p className="bar-percent ml-auto">65%</p>
          </div>

          <div className="mt-5 mb-3">
            <ProgressBar variant="info" now={45} />
          </div>
          <div className="d-flex">
            <span className="mb-4">Public Administration</span>
            <p className="bar-percent ml-auto">45%</p>
          </div>

          <div className="mt-5 mb-3">
            <ProgressBar variant="info" now={25} />
          </div>
          <div className="d-flex">
            <span className="mb-2">Educational Services</span>
            <p className="bar-percent ml-auto">25%</p>
          </div>
        </div>
      </div>
    </>
  );
}

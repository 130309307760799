import { ADD_ZEROES } from "../../_metronic/_helpers/helperFunctions";
import * as requestFromServer from "./campaignsCrud";
import { campaignsSlice, callTypes } from "./campaignsSlice";

const { actions } = campaignsSlice;

export const fetchCampaigns = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllCampaigns()
    .then((response) => {
      const { totalResults, results } = response.data;

      let refactoredData = [];
      if (results && results.length) {
        refactoredData = results.map((item, index) => {
          return { ...item, ID: ADD_ZEROES(index + 1) };
        });
      }

      dispatch(
        actions.campaignsFetched({
          totalCount: totalResults,
          entities: refactoredData,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const filterCampaigns = (name) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .serachCampaign(name)
    .then((response) => {
      const { totalResults, results } = response.data;

      let refactoredData = [];
      if (results && results.length) {
        refactoredData = results.map((item, index) => {
          return { ...item, ID: ADD_ZEROES(index + 1) };
        });
      }

      dispatch(
        actions.campaignsFetched({
          totalCount: totalResults,
          entities: refactoredData,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCampaign = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.campaignFetched({ campaignForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCampaignById(id)
    .then((response) => {
      const campaign = response.data;
      dispatch(actions.campaignFetched({ campaignForEdit: campaign }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find campaign";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCampaign = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCampaign(id)
    .then((response) => {
      // dispatch(actions.campaignDeleted({ id }));
      dispatch(fetchCampaigns());
    })
    .catch((error) => {
      error.clientMessage = "Can't delete campaign";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCampaign = (campaignForCreation, setModalShow) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCampaign(campaignForCreation)
    .then((response) => {
      setModalShow(true);
      dispatch(actions.campaignCreated({ campaign: response.data }));
    })
    .catch((error) => {
      error.clientMessage = error.message;
      let errorData = error?.response?.data?.message;
      dispatch(
        actions.catchError({
          error,
          callType: callTypes.action,
          errorType: "createError",
          errorData,
        })
      );
    });
};

export const updateCampaign = (id, campaign, setModalShow) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCampaign(id, campaign)
    .then((response) => {
      setModalShow(true);
      dispatch(actions.campaignUpdated({ campaign: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update campaign";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCampaignsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCampaigns(ids, status)
    .then(() => {
      dispatch(actions.campaignsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update campaigns status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCampaigns = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCampaigns(ids)
    .then(() => {
      dispatch(actions.campaignsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete campaigns";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getCustomCampaign = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomCampaign()
    .then((response) => {
      dispatch(
        actions.CampaignGet({
          customCampaign: response.data.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getIndustry = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getIndustries()
    .then((response) => {
      dispatch(
        actions.industriesFetched({
          entities: response.data.results,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "not running";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createIndustry = (industryCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createIndustry(industryCreation)
    .then(() => {
      dispatch(getIndustry());
    })
    .catch((error) => {
      error.clientMessage = "Can't create industry";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



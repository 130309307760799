import axios from "axios";

export const CUSTOMERS_URL = "/campaigns";

// CREATE =>  POST: add a new campaign to the server
export function createCampaign(campaign) {
  return axios.post(`/campaigns`, campaign);
}

// READ

export function getCampaignById(id) {
  return axios.get(`${CUSTOMERS_URL}/${id}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCampaigns(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the campaign on the server
export function updateCampaign(campaignId, data) {
  return axios.patch(`${CUSTOMERS_URL}/${campaignId}`, data);
}
// UPDATE Status
export function updateStatusForCampaigns(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCampaigns`, {
    ids,
    status,
  });
}

// DELETE => delete the campaign from the server
export function deleteCampaign(campaignId) {
  return axios.delete(`${CUSTOMERS_URL}/${campaignId}`);
}

// DELETE Campaigns by ids
export function deleteCampaigns(id) {
  return axios.post(`/campaigns/${id}`);
}

export function getCustomCampaign() {
  return axios.get(`/custom/campaigns`);
}

export function getAllCampaigns() {
  return axios.get(`/campaigns`);
}
export function getIndustries() {
  return axios.get(`/industries?limit=500&page=1`);
}

export function createIndustry(industry) {
  return axios.post(`/industries`, industry);
}
export function serachCampaign(params) {
  return axios.get(`/campaigns/search?${
    params.name ? `campaignName=${params.name}` : ""
  }${
    params.pageNumber
      ? `&limit=${params.pageSize}&page=${params.pageNumber}`
      : ""
  }
   `);
}
